<template>
    <div>
        testtesttesttesttest
        <button @click="gotoTtt2">跳转到/ttt2</button>
    </div>
</template>

<script>
import { H5test } from '@/api/test'
export default {
    name: 'dd',
    props: [''],
    data() {
        return {

        };
    },

    components: {},

    methods: {
        gotoTtt2() {
            this.$router.push('/ttt2')
        }
    },

    created() {
        H5test()

    }

}


</script>



<style scoped></style>