<template>
    <div>
        测试2

    </div>
</template>

<script>

export default {
    name: '',
    props: [''],
    data() {
        return {

        };
    },

    components: {},

    methods: {},

    created() { }

}


</script>



<style scoped></style>