import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false



// Vant UI
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// 移动端自适应
import 'amfe-flexible'

// 使用vue路由
import router from './router/router';


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
