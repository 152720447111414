// 引入axios
import axios from 'axios'


const instance = axios.create({
    baseURL: 'http://localhost:8805/',
    headers: {
        'h5test': "h5test"
    }
})

// 请求拦截器  
axios.interceptors.request.use(config => {
    return config

})
// 响应拦截器  
axios.interceptors.response.use(res => {
    return res.data
}, error => {
    return Promise.reject(new Error('faile'))
})

export default instance  