import Vue from 'vue'
import VueRouter from 'vue-router'
//导入界面组件
import test from "../components/tes.vue"
import test2 from "../components/test2.vue"
Vue.use(VueRouter)



const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        // {
        //     path: '/',
        //     name: 'home',
        //     component: home,
        //     meta: {
        //         name: '首页'
        //     }
        // },
        {
            path: '/',
            name: 'test',
            component: test,
            meta: {
                name: '测试'
            }
        },
        {
            path: '/ttt2',
            name: 'test222',
            component: test2,
            meta: {
                name: '测试222'
            }
        }
    ]
})


router.beforeEach((to, from, next) => {
    // 动态标题
    if (to.meta && to.meta.name) {
        document.title = to.meta.name;
    } else {
        document.title = "Hello";
    }
    next();
});

export default router;